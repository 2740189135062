import { ReactElement, useEffect, useMemo, useState } from "react";
import * as css from "./styles/css.module.scss";
import { SERIES_DATA_OPTION, PropertyExecutiveSummaryData, usePropertyExecutiveSummaryData } from "./logic/usePopertyExecutiveSummaryData";
import { useProperties } from "../../contexts/properties/PropertiesContext";
import { formatterDollarUSNoDecimal,
         formatterPercent,
         formatterPercentWithSign,
         formatterDollarUSNoDecimalSignAlways } from "../../utils/formatters";
import { OperationalGraph } from "../planning-dashboard/components/operational-graph/OperationalGraph";
import GraphTableWidget from "../planning-dashboard/components/graph-table-widget/GraphTableWidget";
import MenuTitle from "../../components/menu-title/MenuTitle";
import { IMenuTitleOption } from "../../components/menu-title/logic/menuTitle";
import * as widgetCss from "./styles/widget.module.scss";
import * as statCSS from "../../components/account-summary/styles/styles.module.scss";
import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import WidgetLoading from "../planning-dashboard/components/widget-loading/WidgetLoading";
import { ThemeProvider } from "styled-components";
import {ViziblyTheme} from "../../styles/zendesk-garden/ViziblyZDGTheme";
import OperationalTable from "../planning-dashboard/components/operational-table/OperationalTable";
import { ExecutiveSummaryGraphData } from "./logic/seriesGraphDataLogic";
import useAppStore from "../../hooks/useAppStore";
import { FinancialReportTable } from "./components/FinancialSummaryTable";

export const OPERATIONAL_MENU_OPTIONS: IMenuTitleOption[] = [
    { label: 'Occupancy', value: SERIES_DATA_OPTION.OCCUPANCY },
    { label: 'Expirations', value: SERIES_DATA_OPTION.EXPIRATIONS },
    { label: 'Renewals', value: SERIES_DATA_OPTION.RENEWALS },
    { label: 'Renewal Ratios', value: SERIES_DATA_OPTION.RENEWAL_RATIOS },
    // { label: 'Renewal Trade Outs', value: SERIES_DATA_OPTION.RENEWAL_TRADEOUTS },
    { label: 'Move Outs', value: SERIES_DATA_OPTION.MOVE_OUTS },
    { label: 'Move Out Ratios', value: SERIES_DATA_OPTION.MOVE_OUTS_RATIOS },
    // { label: 'New Lease Trade Outs', value: SERIES_DATA_OPTION.NEW_LEASE_TRADEOUTS }
    // { label: 'Rents', value: SERIES_DATA_OPTION.MOVE_OUTS_RATIOS },
    { label: 'Net Operating Income', value: SERIES_DATA_OPTION.NOI },
    { label: 'Rental Income', value: SERIES_DATA_OPTION.RENTAL_INCOME },
    { label: 'Total Expenses', value: SERIES_DATA_OPTION.TOTAL_EXPENSE },
    { label: 'Budgeted Market Rents', value: SERIES_DATA_OPTION.BUDGETED_MARKET_RENT },
];


export function PropertyExecutiveSummary(): ReactElement {
    const {currentProperty} = useProperties();
    const data = usePropertyExecutiveSummaryData(currentProperty);
    const [selectedGraphMetric, setSelectedGraphMetric] = useState<SERIES_DATA_OPTION>(SERIES_DATA_OPTION.OCCUPANCY);
    const appStore = useAppStore();
    useEffect(() => {
        appStore.set({isLoading: false});
    }, []);

    const chartData:ExecutiveSummaryGraphData|undefined = useMemo(
        () => {
            if (!data) {
                return undefined;
            }
            return data.graphSeriesData.get(selectedGraphMetric);
        },
        [data, selectedGraphMetric]
    );

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={css.wrapper}>
                <div className={css.header}>
                    <h1>{data ? data.property.name : ""}</h1>
                    <span>{data ? data.property.budgetYear : ""} Operating Budget</span>
                    <span>Presented by {data ? data.user.clientName : ""}</span>
                </div>
                <div className={css.tableOfContents}>
                    <h2>
                        Table of Contents
                    </h2>
                    <ol>
                        <li>
                            <div>
                                <h3>Executive Summary</h3>
                                <span>
                                    This section provides an overview of the operating plan
                                    for {data ? data.property.name : ""} in {data ? data.property.budgetYear : ""}
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h3>Rents</h3>
                                <span>This section provides an overview of the property's rental income, occupancy rates, and renewal/move-out assumptions.</span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h3>Financial Summary</h3>
                                <span>This section presents a detailed analysis of the property's financial performance, including revenue, expenses, and profitability.</span>
                            </div>
                        </li>
                    </ol>
                </div>
                <div className={css.executiveSummary}>
                    <h2>
                        Executive Summary
                    </h2>
                    <span>
                        {buildExecutiveSummaryStatementNOI(data)}
                        {buildExecutiveSummaryStatementRents(data)}
                        {buildExecutiveSummaryStatementRentalIncome(data)}
                        {buildExecutiveSummaryStatementTotalExpense(data)}
                    </span>
                </div>
                <div className={css.rentSummary}>
                    <h2>
                        Rent Summary
                    </h2>
                    <div className={css.cards}>
                        <div className={css.metricCard}>
                            <h3>Rents</h3>
                            <span>{buildRentsStatementMarketRent(data)}</span>
                            <span>{buildRentsStatementNewLeaseTradeOut(data)}</span>
                            <span>{buildRentsStatementRenewalTradeOut(data)}</span>
                        </div>
                        <div className={css.metricCard}>
                            <h3>Occupancy</h3>
                            <span>{buildRentsStatementOccupancy(data)}</span>
                        </div>
                        <div className={css.metricCard}>
                            <h3>Renewal / Move Outs</h3>
                            <span>{buildRentsStatementRenewalRatio(data)}</span>
                            <span>{buildRentsStatementMoveOutRatio(data)}</span>
                        </div>
                    </div>
                    <span>
                        {buildRentsStatementSummary(data)}
                    </span>
                    <div>
                        {
                            data ?
                            <GraphTableWidget>
                                <GraphTableWidget.Label>
                                    <div className={widgetCss.widgetLabel}>
                                        <MenuTitle
                                            options={OPERATIONAL_MENU_OPTIONS}
                                            onChange={(newVal) => setSelectedGraphMetric(newVal)}
                                            noUnderline
                                        />
                                    </div>
                                    <div className={widgetCss.widgetSublabel}>
                                        <MenuTitle
                                            options={[
                                                {
                                                    label: `${data.property.reforecastYear % 100} RFCST`,
                                                    value: 0,
                                                }
                                            ]}
                                            small
                                            plain
                                        />
                                        <span className={widgetCss.widgetSublabelMetricDivider}>vs</span>
                                        <MenuTitle
                                            options={[
                                                {
                                                    label: `${data.property.budgetYear % 100} BDGT`,
                                                    value: 0,
                                                }
                                            ]}
                                            small
                                            plain
                                        />
                                    </div>
                                </GraphTableWidget.Label>
                                <GraphTableWidget.Graph>
                                    <Grid>
                                        <Row justifyContent="center" className={undefined/*css.dashboardRow*/}>
                                            <Col textAlign="center">
                                                <h5 className={statCSS.statLabel}>{chartData?.summary.comparisonItemOne.label ?? "-"}</h5>
                                                <h5 className={statCSS.statValue}>{chartData?.summary.comparisonItemOne.value ?? "-"}</h5>
                                            </Col>

                                            <Col textAlign="center">
                                            <h5 className={statCSS.statLabel}>{chartData?.summary.comparisonItemTwo.label ?? "-"}</h5>
                                                <h5 className={statCSS.statValue}>{chartData?.summary.comparisonItemTwo.value ?? "-"}</h5>
                                            </Col>

                                            <Col textAlign="center">
                                                <h5 className={statCSS.statLabel}>{chartData?.summary.variancePercent.label}</h5>
                                                <h5 className={statCSS.statValue}>{chartData?.summary.variancePercent.value}</h5>
                                            </Col>

                                            <Col textAlign="center">
                                                <h5 className={statCSS.statLabel}>{chartData?.summary.varianceAmount.label}</h5>
                                                <h5 className={statCSS.statValue}>{chartData?.summary.varianceAmount.value}</h5>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                {chartData && selectedGraphMetric?
                                                <OperationalGraph
                                                    chartData={{varianceCounts: chartData.varianceCounts, chartSeriesData: chartData.chartSeriesData}}
                                                    dataType={chartData.dataType}
                                                />
                                                : <WidgetLoading />
                                                }
                                            </Col>
                                        </Row>
                                    </Grid>
                                </GraphTableWidget.Graph>
                                <GraphTableWidget.Table>
                                    {
                                        chartData?.tableData !== undefined ?
                                            <OperationalTable tableData={chartData.tableData} />
                                            : <WidgetLoading />
                                    }
                                </GraphTableWidget.Table>
                            </GraphTableWidget>
                            : <WidgetLoading />
                        }
                    </div>
                </div>
                <div className={css.financialSummary}>
                    <h2>
                        Financial Summary
                    </h2>
                    {data &&
                    <div>
                        {data.reportTables.map(reportTable =>
                            <FinancialReportTable
                                reforecastYear={data.property.reforecastYear}
                                budgetYear={data.property.budgetYear}
                                data={reportTable}
                            />
                        )}
                    </div>
                    }
                </div>
            </div>
        </ThemeProvider>
    );
}


function buildExecutiveSummaryStatementNOI(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data) {
        return "";
    }
    let ret = `${data.property.name} is projected to`;
    if (data.noiDelta > 0) {
        ret = ret.concat(
            ` increase NOI in ${data.property.budgetYear}
            by ${formatterDollarUSNoDecimal.format(data.noiDelta)}`);
        if (data.noiDeltaPercent != 0) {
            ret = ret.concat(` (${formatterPercentWithSign.format(data.noiDeltaPercent)})`);
        }
    }
    else if (data.noiDelta < 0) {
        ret = ret.concat(
            ` decrease NOI in ${data.property.budgetYear}
            by ${formatterDollarUSNoDecimal.format(-data.noiDelta)}`);
        if (data.noiDeltaPercent != 0) {
            ret = ret.concat(` (${formatterPercentWithSign.format(data.noiDeltaPercent)})`);
        }
    }
    else {
        ret = ret.concat(
            ` have no changes in NOI in ${data.property.budgetYear} compared to ${data.property.reforecastYear}`
        );
    }
    return ret + ".";
}

function buildExecutiveSummaryStatementRents(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data || data.avgMarketRentBudget == null || data.avgMarketRentReforecast == null) {
        return "";
    }
    let ret = " Rents are forecasted to";
    if (data.avgMarketRentDeltaPercent == 0 || data.avgMarketRentDeltaPercent == null) {
        ret = ret.concat(
            ` be at ${formatterDollarUSNoDecimal.format(data.avgMarketRentBudget)} by December ${data.property.budgetYear}`
        );
    }
    else {
        ret = ret.concat(
            ` ${data.avgMarketRentDeltaPercent < 0 ? "decrease" : "increase"} from`,
            ` ${formatterDollarUSNoDecimal.format(data.avgMarketRentReforecast)}`,
            ` to ${formatterDollarUSNoDecimal.format(data.avgMarketRentBudget)}`,
            ` (${formatterPercentWithSign.format(data.avgMarketRentDeltaPercent)})`,
            ` by December ${data.property.budgetYear}`
        );
    }
    if (data.avgOccupancy != null) {
        ret = ret.concat(
            ` at an average occupancy of ${formatterPercent.format(data.avgOccupancy)}`
        );
    }
    ret = ret.concat(".");
    return ret;
}

function buildExecutiveSummaryStatementRentalIncome(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data) {
        return "";
    }

    let ret = "";
    if (data.rentalIncomeDelta != 0) {
        ret = ` Overall change in rental income is expected at ${formatterDollarUSNoDecimalSignAlways.format(data.rentalIncomeDelta)}`;
        if (data.rentalIncomeDeltaPercent != 0) {
            ret = ret.concat(` (${formatterPercentWithSign.format(data.rentalIncomeDeltaPercent)})`);
        }
        ret = ret.concat(".");
    }
    else {
        ret = " No change is expected in rental income.";
    }

    return ret;
}

function buildExecutiveSummaryStatementTotalExpense(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data) {
        return "";
    }
    let ret = " Total Operating Expense growth is projected to";
    if (data.totalExpenseDelta > 0) {
        ret = ret.concat(
            ` increase by ${formatterDollarUSNoDecimal.format(data.totalExpenseDelta)}`);
        if (data.totalExpenseDeltaPercent != 0) {
            ret = ret.concat(` (${formatterPercentWithSign.format(data.totalExpenseDeltaPercent)})`);
        }
    }
    else if (data.totalExpenseDelta < 0) {
        ret = ret.concat(
            ` decrease by ${formatterDollarUSNoDecimal.format(-data.totalExpenseDelta)}`);
        if (data.totalExpenseDeltaPercent != 0) {
            ret = ret.concat(` (${formatterPercentWithSign.format(data.totalExpenseDeltaPercent)})`);
        }
    }
    else {
        ret = ret.concat(
            ` have have no changes`
        );
    }
    return ret + ".";
}

function buildRentsStatementMarketRent(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data || data.budgetMarketRent == null) {
        return "";
    }

    return `${formatterDollarUSNoDecimal.format(data.budgetMarketRent)} Budgeted Market Rents`;
}

function buildRentsStatementNewLeaseTradeOut(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data || data.budgetMarketRent == null) {
        return "";
    }

    let ret = "";
    if (data.newLeaseTradeOut !== null) {
        ret = formatterDollarUSNoDecimal.format(data.newLeaseTradeOut);
        if (data.newLeaseTradeOutPercent !== null) {
            ret = ret.concat(` (${formatterPercent.format(data.newLeaseTradeOutPercent)})`);
        }
        ret = ret.concat(" New Lease Trade Out");
    }

    return ret;
}

function buildRentsStatementRenewalTradeOut(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data || data.budgetMarketRent == null) {
        return "";
    }

    let ret = "";
    if (data.renewalTradeOut !== null) {
        ret = formatterDollarUSNoDecimal.format(data.renewalTradeOut);
        if (data.renewalTradeOutPercent !== null) {
            ret = ret.concat(` (${formatterPercent.format(data.renewalTradeOutPercent)})`);
        }
        ret = ret.concat(" Renewal Trade Out");
    }

    return ret;
}

function buildRentsStatementOccupancy(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data || data.avgOccupancy == null) {
        return "";
    }
    return `${formatterPercent.format(data.avgOccupancy)} Average Occupancy`;
}

function buildRentsStatementSummary(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data) {
        return "";
    }
    let ret = "";
    if (data.marketRentGrowthPercent !== null) {
        ret = ret.concat(`The projected rent growth for ${data.property.budgetYear} is ${formatterPercentWithSign.format(data.marketRentGrowthPercent)}.`);
    }
    let newLeaseTradeOutPart: string | null = null;
    let renewalTradeOutPart: string | null = null;
    if (data.newLeaseTradeOut !== null) {
        newLeaseTradeOutPart = formatterDollarUSNoDecimal.format(data.newLeaseTradeOut);
        if (data.newLeaseTradeOutPercent !== null) {
            newLeaseTradeOutPart = newLeaseTradeOutPart.concat(` (${formatterPercent.format(data.newLeaseTradeOutPercent)})`);
        }
    }
    if (data.renewalTradeOut !== null) {
        renewalTradeOutPart = formatterDollarUSNoDecimal.format(data.renewalTradeOut);
        if (data.renewalTradeOutPercent !== null) {
            renewalTradeOutPart = renewalTradeOutPart.concat(` (${formatterPercent.format(data.renewalTradeOutPercent)})`);
        }
    }

    if (newLeaseTradeOutPart && renewalTradeOutPart) {
        ret = ret.concat(` This will result in ${newLeaseTradeOutPart} New Lease Trade Outs and ${renewalTradeOutPart} Renewal Trade Outs.`);
    }
    else if (newLeaseTradeOutPart) {
        ret = ret.concat(` This will result in ${newLeaseTradeOutPart} New Lease Trade Outs.`);
    }
    else if (renewalTradeOutPart) {
        ret = ret.concat(` This will result in ${renewalTradeOutPart} Renewal Trade Outs.`);
    }
    if (data.avgOccupancy !== null) {
        ret = ret.concat(` ${data.property.name} is expected to operate at an average ${formatterPercent.format(data.avgOccupancy)}
         occupancy, resulting in `);
        if (data.rentalIncomeDelta != 0) {
            ret = ret.concat(` a ${formatterDollarUSNoDecimal.format(data.rentalIncomeDelta * Math.sign(data.rentalIncomeDelta))}`);
            if (data.rentalIncomeDeltaPercent != 0) {
                ret = ret.concat(` (${formatterPercentWithSign.format(data.rentalIncomeDeltaPercent)})`);
            }
            if (data.rentalIncomeDelta > 0) {
                ret = ret.concat(" increase");
            }
            else {
                ret = ret.concat(" decrease");
            }
            ret = ret.concat(` in rental income versus ${data.property.reforecastYear}.`);
        }
        else {
            ret = ` no change in rental income versus ${data.property.reforecastYear}.`;
        }
    }
    return ret;
}

function buildRentsStatementRenewalRatio(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data || data.budgetExpiringRenewalRatio == null) {
        return "";
    }

    return `${formatterPercent.format(data.budgetExpiringRenewalRatio)} Renewal Ratio`;
}

function buildRentsStatementMoveOutRatio(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data || data.budgetExpiringMoveOutRatio == null) {
        return "";
    }

    return `${formatterPercent.format(data.budgetExpiringMoveOutRatio)} Move Out Ratio`;
}