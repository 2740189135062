
import { useContext, useEffect, useState } from "react";
import { GetPropertyExecutiveSummaryDataQuery, TopCardTagType, useGetPropertyExecutiveSummaryDataLazyQuery } from "../../../__generated__/generated_types";
import {Property} from "../../../contexts/properties/PropertiesContext";
import {AuthContext, AuthUser} from "../../../contexts/AuthContext";
import {buildSummaryDataAverageMarketRent,
        buildSummaryDataAverageOccupancy,
        buildSummaryDataExpiringCounts,
        buildSummaryDataFinancialMetric,
        buildSummaryDataTradeOuts,
} from "./logic";
import { buildGraphSeriesDataExpirations, buildGraphSeriesDataFinancialMetric, buildGraphSeriesDataMoveOutRatios, buildGraphSeriesDataMoveOuts, buildGraphSeriesDataOccupancy, buildGraphSeriesDataRenewalRatios, buildGraphSeriesDataRenewals, ExecutiveSummaryGraphData } from "./seriesGraphDataLogic";
import { buildReportTablesData, ReportTableData } from "./reportTablesLogic";
import { FinancialEntity, useChartOfAccounts } from "../../../contexts/chartofaccounts/ChartOfAccountsContext";


export enum SERIES_DATA_OPTION {
    EXPIRATIONS,
    OCCUPANCY,
    RENEWALS,
    RENEWAL_RATIOS,
    RENEWAL_TRADEOUTS,
    MOVE_OUTS,
    MOVE_OUTS_RATIOS,
    NEW_LEASE_TRADEOUTS,
    NOI,
    RENTAL_INCOME,
    TOTAL_EXPENSE,
    BUDGETED_MARKET_RENT,
}

export type PropertyExecutiveSummaryData = {
    property: Property,
    user: AuthUser,

    financialMetricsSetupRequired: boolean,

    noiDelta: number,
    noiDeltaPercent: number,

    rentalIncomeDelta: number,
    rentalIncomeDeltaPercent: number,

    totalExpenseDelta: number,
    totalExpenseDeltaPercent: number,

    avgMarketRentReforecast: number | null,
    avgMarketRentBudget: number | null,
    avgMarketRentDeltaPercent: number | null,

    avgOccupancy: number | null,

    budgetMarketRent: number | null,
    marketRentGrowthPercent: number | null,

    budgetExpirations: number | null,
    budgetExpiringMoveOuts: number | null,
    budgetExpiringRenewals: number | null,
    budgetExpiringMoveOutRatio: number | null,
    budgetExpiringRenewalRatio: number | null,

    newLeaseTradeOut: number | null,
    newLeaseTradeOutPercent: number | null,
    renewalTradeOut: number | null,
    renewalTradeOutPercent: number | null,

    graphSeriesData: Map<SERIES_DATA_OPTION, ExecutiveSummaryGraphData>,
    reportTables: ReportTableData[]
}

export function usePropertyExecutiveSummaryData(property: Property | undefined): PropertyExecutiveSummaryData | undefined {
    const [loadData, { data, loading }] = useGetPropertyExecutiveSummaryDataLazyQuery({ fetchPolicy: "no-cache" });
    const {user} = useContext(AuthContext);
    const coa = useChartOfAccounts();
    const [executiveSummaryData, setExecutiveSummaryData] = useState<PropertyExecutiveSummaryData>();
    useEffect(
        () => {
            if (!property) {
                return;
            }
            loadData({
                variables: {
                    propertyId: property.id,
                    budgetYear: property.budgetYear,
                    topCardTagTypes: []
                }
            });
        },
        [property]
    );

    useEffect(
        () => {
            if (property && data && !loading && user && coa.isReady && coa.chartOfAccountsFlat) {
                const executiveSummaryData = buildSummaryData(property, user, data, coa.chartOfAccountsFlat);
                setExecutiveSummaryData(executiveSummaryData);
            }
        },
        [data, loading, user, coa.isReady, coa.chartOfAccountsFlat]
    );
    return executiveSummaryData;
}

function buildSummaryData(property: Property,
                          user: AuthUser,
                          rawData: GetPropertyExecutiveSummaryDataQuery,
                          chartOfAccountsFlat: FinancialEntity[]): PropertyExecutiveSummaryData {
    const noiStat = buildSummaryDataFinancialMetric(property, rawData, TopCardTagType.NetOperatingIncome);
    const avgMarketRentStat = buildSummaryDataAverageMarketRent(rawData);
    const occupancyStat = buildSummaryDataAverageOccupancy(property, rawData);
    const rentalIncomeStat = buildSummaryDataFinancialMetric(property, rawData, TopCardTagType.RentalIncome);
    const totalExpenseStat = buildSummaryDataFinancialMetric(property, rawData, TopCardTagType.TotalExpenses);
    const budgetedMarketRentStat = buildSummaryDataFinancialMetric(property, rawData, TopCardTagType.MarketRent);
    const budgetedAvgMarketRent = budgetedMarketRentStat.budgetMonthly.average() / occupancyStat.budgetUnitCountMonthly.average();
    const expiringCountsStat = buildSummaryDataExpiringCounts(property, rawData);
    const tradeOutsStat = buildSummaryDataTradeOuts(rawData);
    const reportTables = buildReportTablesData(property, rawData, chartOfAccountsFlat, occupancyStat.budgetUnitCountMonthly.average());
    const executiveSummaryData:PropertyExecutiveSummaryData = {
        property: property,
        user: user,

        financialMetricsSetupRequired: false,

        noiDelta: noiStat.varianceAmount == null ? 0 : noiStat.varianceAmount,
        noiDeltaPercent: noiStat.variancePercent == null ? 0 : noiStat.variancePercent,
        rentalIncomeDelta: rentalIncomeStat.varianceAmount == null ? 0 : rentalIncomeStat.varianceAmount,
        rentalIncomeDeltaPercent: rentalIncomeStat.variancePercent == null ? 0 : rentalIncomeStat.variancePercent,
        totalExpenseDelta: totalExpenseStat.varianceAmount == null ? 0 : totalExpenseStat.varianceAmount,
        totalExpenseDeltaPercent: totalExpenseStat.variancePercent == null ? 0 : totalExpenseStat.variancePercent,
        avgMarketRentReforecast: avgMarketRentStat.reforecastValue,
        avgMarketRentBudget: avgMarketRentStat.budgetValue,
        avgMarketRentDeltaPercent: avgMarketRentStat.deltaPercent,
        avgOccupancy: occupancyStat.budgetAvgOccupancy,

        budgetMarketRent: budgetedAvgMarketRent,
        marketRentGrowthPercent: budgetedMarketRentStat.variancePercent,

        budgetExpirations: expiringCountsStat.budgetExpirations,
        budgetExpiringMoveOuts: expiringCountsStat.budgetExpiringMoveOuts,
        budgetExpiringRenewals: expiringCountsStat.budgetExpiringRenewals,
        budgetExpiringMoveOutRatio: expiringCountsStat.budgetExpiringMoveOutRatio,
        budgetExpiringRenewalRatio: expiringCountsStat.budgetExpiringRenewalRatio,

        newLeaseTradeOut: tradeOutsStat.newLeaseTradeOut,
        newLeaseTradeOutPercent: tradeOutsStat.newLeaseTradeOutPercent,
        renewalTradeOut: tradeOutsStat.renewalTradeOut,
        renewalTradeOutPercent: tradeOutsStat.renewalTradeOutPercent,

        graphSeriesData: new Map(),

        reportTables: reportTables
    };

    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.OCCUPANCY, buildGraphSeriesDataOccupancy(occupancyStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.EXPIRATIONS, buildGraphSeriesDataExpirations(expiringCountsStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.RENEWALS, buildGraphSeriesDataRenewals(expiringCountsStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.RENEWAL_RATIOS, buildGraphSeriesDataRenewalRatios(expiringCountsStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.MOVE_OUTS, buildGraphSeriesDataMoveOuts(expiringCountsStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.MOVE_OUTS_RATIOS, buildGraphSeriesDataMoveOutRatios(expiringCountsStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.NOI, buildGraphSeriesDataFinancialMetric(noiStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.RENTAL_INCOME, buildGraphSeriesDataFinancialMetric(rentalIncomeStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.TOTAL_EXPENSE, buildGraphSeriesDataFinancialMetric(totalExpenseStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.BUDGETED_MARKET_RENT, buildGraphSeriesDataFinancialMetric(budgetedMarketRentStat, property));

    return executiveSummaryData;
}
