import { useHistory, useRouteMatch } from "react-router-dom";
import { parseCurrentDestination } from "../logic/parseCurrentDestination";
import { ReportsNavMenuConfig } from "../ReportsNavMenuConfig";

export enum Destination {
    Overview = "overview",
    LineItems = "line_items",
    PropertyExecutiveSummary = "property_executive_summary",
    MVR = "monthly_variance_report",
    FinancialReports = "financial_reports",
    OperationalReports = "operational_reports",
}

export type UseNavigationAPI = {
    goto: (destination: Destination) => void;
    currentDestination: Destination | undefined;
}

export function useNavigation(): UseNavigationAPI {
    const routerMatch = useRouteMatch();
    const history = useHistory();

    const currentDestination = parseCurrentDestination(history.location.pathname);

    const goto = (destination: Destination) => {
        let newPath = `${routerMatch.path}${ReportsNavMenuConfig.OVERVIEW_PATH}`;
        if (destination === Destination.Overview) {
            newPath = `${routerMatch.path}${ReportsNavMenuConfig.OVERVIEW_PATH}`;
        }
        else if (destination == Destination.LineItems) {
            newPath = `${routerMatch.path}${ReportsNavMenuConfig.LINE_ITEMS_PATH}`;
        }
        else if (destination == Destination.PropertyExecutiveSummary) {
            newPath = `${routerMatch.path}${ReportsNavMenuConfig.PROPERTY_EXECUTIVE_SUMMARY}`;
        }
        else if (destination == Destination.MVR){
            newPath = `${routerMatch.path}${ReportsNavMenuConfig.MVR_PATH}`;
        }
        else if (destination == Destination.FinancialReports){
            newPath = `${routerMatch.path}${ReportsNavMenuConfig.FINANCIAL_REPORTS_PATH}`;
        }
        else if (destination == Destination.OperationalReports){
            newPath = `${routerMatch.path}${ReportsNavMenuConfig.OPERATIONAL_REPORTS_PATH}`;
        }
        history.push(newPath);
    };

    return {
        goto: goto,
        currentDestination: currentDestination
    };
}
