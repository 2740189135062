import { GetPropertyExecutiveSummaryDataQuery, ReportTableViewModel, VersionType } from "../../../__generated__/generated_types";
import { FinancialEntity } from "../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { Property } from "../../../contexts/properties/PropertiesContext";
import "native-injects";

const EPSILON = 0.01;

export type ReportTableDataRow = {
    accountId: string;
    name: string;
    order: number;
    reforecastTotal: number | null;
    budgetTotal: number | null;
    varianceAmount: number | null;
    variancePercent: number | null;
    budgetPerUnit: number | null;
}

export type ReportTableData = {
    id: string;
    name: string;
    order: number;
    rows: ReportTableDataRow[]
}

export function buildReportTablesData(property: Property,
                                      rawData: GetPropertyExecutiveSummaryDataQuery,
                                      chartOfAccountsFlat: FinancialEntity[],
                                      unitCount: number): ReportTableData[] {
    let ret:ReportTableData[] = [];
    const chartOfAccountsFlatById = chartOfAccountsFlat.toIdMap("id");
    for (const rawTableData of rawData.getReportTables) {
        const tableData = _buildReportTableData(property,
                                                rawData,
                                                rawTableData,
                                                chartOfAccountsFlatById,
                                                unitCount);
        ret.push(tableData);
    }
    ret = ret.sortBy("order");
    return ret;
}

function _buildReportTableData(property: Property,
                               rawData: GetPropertyExecutiveSummaryDataQuery,
                               rawTableData: ReportTableViewModel,
                               chartOfAccountsFlatById: Record<string, FinancialEntity>,
                               unitCount: number): ReportTableData {
    const resultRowsMap = new Map<string, ReportTableDataRow>();
    for (const accountIn of rawTableData.accounts) {
        const coaAccount = chartOfAccountsFlatById[accountIn.accountId];
        if (!coaAccount) {
            continue;
        }
        resultRowsMap.set(coaAccount.id, {
            accountId: coaAccount.id,
            name: coaAccount.name + (coaAccount.number ? ` (${coaAccount.number})` : ""),
            order: coaAccount.order,
            reforecastTotal: null,
            budgetTotal: null,
            varianceAmount: null,
            variancePercent: null,
            budgetPerUnit: null
        });
    }
    for (const accountValues of rawData.financialValuesPropertyBudgetSeason) {
        const row = resultRowsMap.get(accountValues.accountId);
        if (!row) {
            continue;
        }
        if (accountValues.year == property.reforecastYear && accountValues.versionType == VersionType.Reforecast) {
            row.reforecastTotal = accountValues.values.sum();
        }
        else if (accountValues.year == property.budgetYear && accountValues.versionType == VersionType.Budget) {
            row.budgetTotal = accountValues.values.sum();
        }
    }

    for (const row of Array.from(resultRowsMap.values())) {
        if (row.reforecastTotal !== null && row.budgetTotal !== null) {
            if (Math.abs(row.reforecastTotal) < EPSILON) {
                row.varianceAmount = Math.abs(row.budgetTotal) < EPSILON ? 0 : row.budgetTotal;
                row.variancePercent = 0;
            }
            else {
                row.varianceAmount = row.budgetTotal - row.reforecastTotal;
                row.variancePercent = row.budgetTotal / row.reforecastTotal - 1;
            }
        }
        if (row.budgetTotal !== null && Math.abs(unitCount) > EPSILON) {
            row.budgetPerUnit = row.budgetTotal / unitCount;
        }
    }
    const resultRows = Array.from(resultRowsMap.values()).sortBy("order");
    return {
        id: rawTableData.id,
        name: rawTableData.name,
        order: rawTableData.order,
        rows: resultRows
    };

}