import { Property } from "../../../contexts/properties/PropertiesContext";
import { TSummaryDataAverageOccupancy, TSummaryDataExpiringCounts, TSummaryDataFinancialMetric } from "../logic/logic";
import { arraySum } from "../../../utils/array";
import { COLORS } from "../../../constants/Colors";
import { formatterDollarUSNoDecimal, formatterInt, formatterPercentAutoSign } from "../../../utils/formatters";
import { CellValue } from "handsontable/common";
import { getHotTableDataFromParsedSeriesData } from "../../planning-dashboard/data/logic/operationalData";
import { VersionType } from "../../../__generated__/generated_types";

export type ExecutiveSummaryGraphData = {
    dataType: 'dollar' | 'percent' | 'count',
    varianceCounts: (number | null)[],
    chartSeriesData: {
        year: number,
        versionType: VersionType,
        values: (number | null)[],
        counts: number[],
        name: string,
        color: string,
        type: string,
        averageValue: number,
        averageCount: number,
        totalValue: number,
        totalCount: number,
    }[],
    summary: {
        comparisonItemOne: {
            label: string,
            value: number | string,
        },
        comparisonItemTwo: {
            label: string,
            value: number | string,
        },
        variancePercent: {
            label: string,
            value: number | string,
        },
        varianceAmount: {
            label: string,
            value: number | string,
        }
    },
    tableData: undefined | {
        colHeaders: string[],
        data: CellValue[][]
    }
}

export function buildGraphSeriesDataOccupancy(occupancyStat: TSummaryDataAverageOccupancy, property: Property): ExecutiveSummaryGraphData {
    const varianceCounts = arraySum(occupancyStat.budgetOccupiedCountMonthly, occupancyStat.reforecastOccupiedCountMonthly, -1);
    const chartSeriesData = [
        {
            year: property.reforecastYear,
            versionType: VersionType.Actuals,
            name: `${property.reforecastYear % 100} REFORECAST - ACTUAL`,
            color: COLORS.LAVENDER_400,
            type: "bar",
            values: occupancyStat.reforecastOccupancy
                .slice(0, property.reforecastStartMonthIndex)
                .map(v => v * 100)
                .concat(new Array(12 - property.reforecastStartMonthIndex).fill(null)),
            counts: occupancyStat.reforecastOccupiedCountMonthly,
            averageValue: occupancyStat.reforecastOccupancy.average() * 100,
            averageCount: occupancyStat.reforecastOccupiedCountMonthly.average(),
            totalValue: occupancyStat.reforecastOccupancy.average() * 100,
            totalCount: occupancyStat.reforecastOccupiedCountMonthly.sum()
        },
        {
            year: property.reforecastYear,
            versionType: VersionType.Reforecast,
            name: `${property.reforecastYear % 100} REFORECAST - FORECAST`,
            color: COLORS.LAVENDER_200,
            type: "bar",
            values: new Array(property.reforecastStartMonthIndex).fill(null)
                .concat(occupancyStat.reforecastOccupancy
                    .slice(property.reforecastStartMonthIndex, 12)
                    .map(v => v * 100)
                ),
            counts: occupancyStat.reforecastOccupiedCountMonthly,
            averageValue: occupancyStat.reforecastOccupancy.average() * 100,
            averageCount: occupancyStat.reforecastOccupiedCountMonthly.average(),
            totalValue: occupancyStat.reforecastOccupancy.average() * 100,
            totalCount: occupancyStat.reforecastOccupiedCountMonthly.sum()
        },
        {
            year: property.budgetYear,
            versionType: VersionType.Budget,
            name: `${property.budgetYear % 100} BUDGET`,
            color: COLORS.PRIMARY_400,
            type: "line",
            values: occupancyStat.budgetOccupancy.map(v => v * 100),
            counts: occupancyStat.budgetOccupiedCountMonthly,
            averageValue: occupancyStat.budgetOccupancy.average() * 100,
            averageCount: occupancyStat.budgetOccupiedCountMonthly.average(),
            totalValue: occupancyStat.budgetOccupancy.average() * 100,
            totalCount: occupancyStat.budgetOccupiedCountMonthly.sum()
        },
    ];
    const summary = {
        comparisonItemOne: {
            label: `${property.reforecastYear % 100} RFCST`,
            value: occupancyStat.reforecastAvgOccupancy === null ? "-" : formatterPercentAutoSign.format(occupancyStat.reforecastAvgOccupancy)
        },
        comparisonItemTwo: {
            label: `${property.budgetYear % 100} BDGT`,
            value: occupancyStat.budgetAvgOccupancy === null ? "-" : formatterPercentAutoSign.format(occupancyStat.budgetAvgOccupancy)
        },
        variancePercent: {
            label: "% Change",
            value: occupancyStat.reforecastAvgOccupancy === null
                    && occupancyStat.budgetAvgOccupancy === null
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    ? "-" : formatterPercentAutoSign.format(occupancyStat.budgetAvgOccupancy! - occupancyStat.reforecastAvgOccupancy!)
        },
        varianceAmount: {
            label: "# Change",
            value: varianceCounts.average().toFixed(0)
        }
    };
    const tableData = getHotTableDataFromParsedSeriesData(
                        {varianceCounts, chartSeriesData},
                        summary,
                        "percent");
    return {
        dataType: "percent",
        varianceCounts: varianceCounts,
        chartSeriesData: chartSeriesData,
        summary: summary,
        tableData
    };
}

export function buildGraphSeriesDataExpirations(expiringCountsStat: TSummaryDataExpiringCounts, property: Property): ExecutiveSummaryGraphData {
    const varianceCounts = arraySum(expiringCountsStat.budgetExpirationsMonthly, expiringCountsStat.reforecastExpirationsMonthly, -1);
    const chartSeriesData = [
        {
            year: property.reforecastYear,
            versionType: VersionType.Actuals,
            name: `${property.reforecastYear % 100} REFORECAST - ACTUAL`,
            color: COLORS.LAVENDER_400,
            type: "bar",
            values: expiringCountsStat.reforecastExpirationsMonthly
                .slice(0, property.reforecastStartMonthIndex)
                .concat(new Array(12 - property.reforecastStartMonthIndex).fill(null)),
            counts: expiringCountsStat.reforecastExpirationsMonthly,
            averageValue: expiringCountsStat.reforecastExpirationsMonthly.average(),
            averageCount: expiringCountsStat.reforecastExpirationsMonthly.average(),
            totalValue: expiringCountsStat.reforecastExpirations ?? 0,
            totalCount: expiringCountsStat.reforecastExpirations ?? 0
        },
        {
            year: property.reforecastYear,
            versionType: VersionType.Reforecast,
            name: `${property.reforecastYear % 100} REFORECAST - FORECAST`,
            color: COLORS.LAVENDER_200,
            type: "bar",
            values: new Array(property.reforecastStartMonthIndex).fill(null)
                .concat(expiringCountsStat.reforecastExpirationsMonthly
                    .slice(property.reforecastStartMonthIndex, 12)
                ),
            counts: expiringCountsStat.reforecastExpirationsMonthly,
            averageValue: expiringCountsStat.reforecastExpirationsMonthly.average(),
            averageCount: expiringCountsStat.reforecastExpirationsMonthly.average(),
            totalValue: expiringCountsStat.reforecastExpirations ?? 0,
            totalCount: expiringCountsStat.reforecastExpirations ?? 0
        },
        {
            year: property.budgetYear,
            versionType: VersionType.Budget,
            name: `${property.budgetYear % 100} BUDGET`,
            color: COLORS.PRIMARY_400,
            type: "line",
            values: expiringCountsStat.budgetExpirationsMonthly,
            counts: expiringCountsStat.budgetExpirationsMonthly,
            averageValue: expiringCountsStat.budgetExpirationsMonthly.average(),
            averageCount: expiringCountsStat.budgetExpirationsMonthly.average(),
            totalValue: expiringCountsStat.budgetExpirations ?? 0,
            totalCount: expiringCountsStat.budgetExpirations ?? 0
        },
    ];
    const summary = {
        comparisonItemOne: {
            label: `${property.reforecastYear % 100} RFCST`,
            value: expiringCountsStat.reforecastExpirations === null ? "-" : formatterInt.format(expiringCountsStat.reforecastExpirations)
        },
        comparisonItemTwo: {
            label: `${property.budgetYear % 100} BDGT`,
            value: expiringCountsStat.budgetExpirations === null ? "-" : formatterInt.format(expiringCountsStat.budgetExpirations)
        },
        variancePercent: {
            label: "% Change",
            value: expiringCountsStat.expitationsVariancePercent === null
                    ? "-" : formatterPercentAutoSign.format(expiringCountsStat.expitationsVariancePercent)
        },
        varianceAmount: {
            label: "# Change",
            value: expiringCountsStat.expirationsVarianceAmount === null ? "-" : formatterInt.format(expiringCountsStat.expirationsVarianceAmount)
        }
    };
    const tableData = getHotTableDataFromParsedSeriesData(
                        {varianceCounts, chartSeriesData},
                        summary,
                        "count");
    return {
        dataType: "count",
        varianceCounts: varianceCounts,
        chartSeriesData: chartSeriesData,
        summary: summary,
        tableData
    };
}

export function buildGraphSeriesDataRenewals(expiringCountsStat: TSummaryDataExpiringCounts, property: Property): ExecutiveSummaryGraphData {
    const varianceCounts = arraySum(expiringCountsStat.budgetExpiringRenewalsMonthly, expiringCountsStat.reforecastExpiringRenewalsMonthly, -1);
    const chartSeriesData = [
        {
            year: property.reforecastYear,
            versionType: VersionType.Actuals,
            name: `${property.reforecastYear % 100} REFORECAST - ACTUAL`,
            color: COLORS.LAVENDER_400,
            type: "bar",
            values: expiringCountsStat.reforecastExpiringRenewalsMonthly
                .slice(0, property.reforecastStartMonthIndex)
                .concat(new Array(12 - property.reforecastStartMonthIndex).fill(null)),
            counts: expiringCountsStat.reforecastExpiringRenewalsMonthly,
            averageValue: expiringCountsStat.reforecastExpiringRenewalsMonthly.average(),
            averageCount: expiringCountsStat.reforecastExpiringRenewalsMonthly.average(),
            totalValue: expiringCountsStat.reforecastExpiringRenewals ?? 0,
            totalCount: expiringCountsStat.reforecastExpiringRenewals ?? 0
        },
        {
            year: property.reforecastYear,
            versionType: VersionType.Reforecast,
            name: `${property.reforecastYear % 100} REFORECAST - FORECAST`,
            color: COLORS.LAVENDER_200,
            type: "bar",
            values: new Array(property.reforecastStartMonthIndex).fill(null)
                .concat(expiringCountsStat.reforecastExpiringRenewalsMonthly
                    .slice(property.reforecastStartMonthIndex, 12)
                ),
            counts: expiringCountsStat.reforecastExpiringRenewalsMonthly,
            averageValue: expiringCountsStat.reforecastExpiringRenewalsMonthly.average(),
            averageCount: expiringCountsStat.reforecastExpiringRenewalsMonthly.average(),
            totalValue: expiringCountsStat.reforecastExpiringRenewals ?? 0,
            totalCount: expiringCountsStat.reforecastExpiringRenewals ?? 0
        },
        {
            year: property.budgetYear,
            versionType: VersionType.Budget,
            name: `${property.budgetYear % 100} BUDGET`,
            color: COLORS.PRIMARY_400,
            type: "line",
            values: expiringCountsStat.budgetExpiringRenewalsMonthly,
            counts: expiringCountsStat.budgetExpiringRenewalsMonthly,
            averageValue: expiringCountsStat.budgetExpiringRenewalsMonthly.average(),
            averageCount: expiringCountsStat.budgetExpiringRenewalsMonthly.average(),
            totalValue: expiringCountsStat.budgetExpiringRenewals ?? 0,
            totalCount: expiringCountsStat.budgetExpiringRenewals ?? 0
        },
    ];
    const summary = {
        comparisonItemOne: {
            label: `${property.reforecastYear % 100} RFCST`,
            value: expiringCountsStat.reforecastExpiringRenewals === null ? "-" : formatterInt.format(expiringCountsStat.reforecastExpiringRenewals)
        },
        comparisonItemTwo: {
            label: `${property.budgetYear % 100} BDGT`,
            value: expiringCountsStat.budgetExpiringRenewals === null ? "-" : formatterInt.format(expiringCountsStat.budgetExpiringRenewals)
        },
        variancePercent: {
            label: "% Change",
            value: expiringCountsStat.expiringRenewalsVariancePercent === null
                    ? "-" : formatterPercentAutoSign.format(expiringCountsStat.expiringRenewalsVariancePercent)
        },
        varianceAmount: {
            label: "# Change",
            value: expiringCountsStat.expiringRenewalsVarianceAmount === null ? "-" : formatterInt.format(expiringCountsStat.expiringRenewalsVarianceAmount)
        }
    };
    const tableData = getHotTableDataFromParsedSeriesData(
                        {varianceCounts, chartSeriesData},
                        summary,
                        "count");
    return {
        dataType: "count",
        varianceCounts: varianceCounts,
        chartSeriesData: chartSeriesData,
        summary: summary,
        tableData
    };
}

export function buildGraphSeriesDataMoveOuts(expiringCountsStat: TSummaryDataExpiringCounts, property: Property): ExecutiveSummaryGraphData {
    const varianceCounts = arraySum(expiringCountsStat.budgetExpiringMoveOutsMonthly, expiringCountsStat.reforecastExpiringMoveOutsMonthly, -1);
    const chartSeriesData = [
        {
            year: property.reforecastYear,
            versionType: VersionType.Actuals,
            name: `${property.reforecastYear % 100} REFORECAST - ACTUAL`,
            color: COLORS.LAVENDER_400,
            type: "bar",
            values: expiringCountsStat.reforecastExpiringMoveOutsMonthly
                .slice(0, property.reforecastStartMonthIndex)
                .concat(new Array(12 - property.reforecastStartMonthIndex).fill(null)),
            counts: expiringCountsStat.reforecastExpiringMoveOutsMonthly,
            averageValue: expiringCountsStat.reforecastExpiringMoveOutsMonthly.average(),
            averageCount: expiringCountsStat.reforecastExpiringMoveOutsMonthly.average(),
            totalValue: expiringCountsStat.reforecastExpiringMoveOuts ?? 0,
            totalCount: expiringCountsStat.reforecastExpiringMoveOuts ?? 0
        },
        {
            year: property.reforecastYear,
            versionType: VersionType.Reforecast,
            name: `${property.reforecastYear % 100} REFORECAST - FORECAST`,
            color: COLORS.LAVENDER_200,
            type: "bar",
            values: new Array(property.reforecastStartMonthIndex).fill(null)
                .concat(expiringCountsStat.reforecastExpiringMoveOutsMonthly
                    .slice(property.reforecastStartMonthIndex, 12)
                ),
            counts: expiringCountsStat.reforecastExpiringMoveOutsMonthly,
            averageValue: expiringCountsStat.reforecastExpiringMoveOutsMonthly.average(),
            averageCount: expiringCountsStat.reforecastExpiringMoveOutsMonthly.average(),
            totalValue: expiringCountsStat.reforecastExpiringMoveOuts ?? 0,
            totalCount: expiringCountsStat.reforecastExpiringMoveOuts ?? 0
        },
        {
            year: property.budgetYear,
            versionType: VersionType.Budget,
            name: `${property.budgetYear % 100} BUDGET`,
            color: COLORS.PRIMARY_400,
            type: "line",
            values: expiringCountsStat.budgetExpiringMoveOutsMonthly,
            counts: expiringCountsStat.budgetExpiringMoveOutsMonthly,
            averageValue: expiringCountsStat.budgetExpiringMoveOutsMonthly.average(),
            averageCount: expiringCountsStat.budgetExpiringMoveOutsMonthly.average(),
            totalValue: expiringCountsStat.budgetExpiringMoveOuts ?? 0,
            totalCount: expiringCountsStat.budgetExpiringMoveOuts ?? 0
        },
    ];
    const summary = {
        comparisonItemOne: {
            label: `${property.reforecastYear % 100} RFCST`,
            value: expiringCountsStat.reforecastExpiringMoveOuts === null ? "-" : formatterInt.format(expiringCountsStat.reforecastExpiringMoveOuts)
        },
        comparisonItemTwo: {
            label: `${property.budgetYear % 100} BDGT`,
            value: expiringCountsStat.budgetExpiringMoveOuts === null ? "-" : formatterInt.format(expiringCountsStat.budgetExpiringMoveOuts)
        },
        variancePercent: {
            label: "% Change",
            value: expiringCountsStat.expiringMoveOutsVariancePercent === null
                    ? "-" : formatterPercentAutoSign.format(expiringCountsStat.expiringMoveOutsVariancePercent)
        },
        varianceAmount: {
            label: "# Change",
            value: expiringCountsStat.expiringMoveOutsVarianceAmount === null ? "-" : formatterInt.format(expiringCountsStat.expiringMoveOutsVarianceAmount)
        }
    };
    const tableData = getHotTableDataFromParsedSeriesData(
                        {varianceCounts, chartSeriesData},
                        summary,
                        "count");
    return {
        dataType: "count",
        varianceCounts: varianceCounts,
        chartSeriesData: chartSeriesData,
        summary: summary,
        tableData
    };
}

export function buildGraphSeriesDataMoveOutRatios(expiringCountsStat: TSummaryDataExpiringCounts, property: Property): ExecutiveSummaryGraphData {
    const varianceCounts = arraySum(expiringCountsStat.budgetExpiringMoveOutsMonthly, expiringCountsStat.reforecastExpiringMoveOutsMonthly, -1);
    const chartSeriesData = [
        {
            year: property.reforecastYear,
            versionType: VersionType.Actuals,
            name: `${property.reforecastYear % 100} REFORECAST - ACTUAL`,
            color: COLORS.LAVENDER_400,
            type: "bar",
            values: expiringCountsStat.reforecastExpiringMoveOutRatiosMonthly
                .slice(0, property.reforecastStartMonthIndex)
                .map(v => v * 100)
                .concat(new Array(12 - property.reforecastStartMonthIndex).fill(null)),
            counts: expiringCountsStat.reforecastExpiringMoveOutsMonthly,
            averageValue: expiringCountsStat.reforecastExpiringMoveOutRatiosMonthly.average() * 100,
            averageCount: expiringCountsStat.reforecastExpiringMoveOutsMonthly.average(),
            totalValue: expiringCountsStat.reforecastExpiringMoveOutRatiosMonthly.average() * 100,
            totalCount: expiringCountsStat.reforecastExpiringMoveOutsMonthly.sum()
        },
        {
            year: property.reforecastYear,
            versionType: VersionType.Reforecast,
            name: `${property.reforecastYear % 100} REFORECAST - FORECAST`,
            color: COLORS.LAVENDER_200,
            type: "bar",
            values: new Array(property.reforecastStartMonthIndex).fill(null)
                .concat(expiringCountsStat.reforecastExpiringMoveOutRatiosMonthly
                    .slice(property.reforecastStartMonthIndex, 12)
                    .map(v => v * 100)
                ),
            counts: expiringCountsStat.reforecastExpiringMoveOutsMonthly,
            averageValue: expiringCountsStat.reforecastExpiringMoveOutRatiosMonthly.average() * 100,
            averageCount: expiringCountsStat.reforecastExpiringMoveOutsMonthly.average(),
            totalValue: expiringCountsStat.reforecastExpiringMoveOutRatiosMonthly.average() * 100,
            totalCount: expiringCountsStat.reforecastExpiringMoveOutsMonthly.sum()
        },
        {
            year: property.budgetYear,
            versionType: VersionType.Budget,
            name: `${property.budgetYear % 100} BUDGET`,
            color: COLORS.PRIMARY_400,
            type: "line",
            values: expiringCountsStat.budgetExpiringMoveOutRatiosMonthly.map(v => v * 100),
            counts: expiringCountsStat.budgetExpiringMoveOutsMonthly,
            averageValue: expiringCountsStat.budgetExpiringMoveOutRatiosMonthly.average() * 100,
            averageCount: expiringCountsStat.budgetExpiringMoveOutsMonthly.average(),
            totalValue: expiringCountsStat.budgetExpiringMoveOutRatiosMonthly.average() * 100,
            totalCount: expiringCountsStat.budgetExpiringMoveOutsMonthly.sum()
        },
    ];
    const summary = {
        comparisonItemOne: {
            label: `${property.reforecastYear % 100} RFCST`,
            value: expiringCountsStat.reforecastAvgExpiringMoveOutRatio === null ? "-" : formatterPercentAutoSign.format(expiringCountsStat.reforecastAvgExpiringMoveOutRatio)
        },
        comparisonItemTwo: {
            label: `${property.budgetYear % 100} BDGT`,
            value: expiringCountsStat.budgetAvgExpiringMoveOutRatio === null ? "-" : formatterPercentAutoSign.format(expiringCountsStat.budgetAvgExpiringMoveOutRatio)
        },
        variancePercent: {
            label: "% Change",
            value: expiringCountsStat.reforecastAvgExpiringMoveOutRatio === null
                    && expiringCountsStat.budgetAvgExpiringMoveOutRatio === null
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    ? "-" : formatterPercentAutoSign.format(expiringCountsStat.budgetAvgExpiringMoveOutRatio! - expiringCountsStat.reforecastAvgExpiringMoveOutRatio!)
        },
        varianceAmount: {
            label: "# Change",
            value: varianceCounts.average().toFixed(0)
        }
    };
    const tableData = getHotTableDataFromParsedSeriesData(
                        {varianceCounts, chartSeriesData},
                        summary,
                        "percent");
    return {
        dataType: "percent",
        varianceCounts: varianceCounts,
        chartSeriesData: chartSeriesData,
        summary: summary,
        tableData
    };
}

export function buildGraphSeriesDataRenewalRatios(expiringCountsStat: TSummaryDataExpiringCounts, property: Property): ExecutiveSummaryGraphData {
    const varianceCounts = arraySum(expiringCountsStat.budgetExpiringRenewalsMonthly, expiringCountsStat.reforecastExpiringRenewalsMonthly, -1);
    const chartSeriesData = [
        {
            year: property.reforecastYear,
            versionType: VersionType.Actuals,
            name: `${property.reforecastYear % 100} REFORECAST - ACTUAL`,
            color: COLORS.LAVENDER_400,
            type: "bar",
            values: expiringCountsStat.reforecastExpiringRenewalRatiosMonthly
                .slice(0, property.reforecastStartMonthIndex)
                .map(v => v * 100)
                .concat(new Array(12 - property.reforecastStartMonthIndex).fill(null)),
            counts: expiringCountsStat.reforecastExpiringRenewalsMonthly,
            averageValue: expiringCountsStat.reforecastExpiringRenewalRatiosMonthly.average() * 100,
            averageCount: expiringCountsStat.reforecastExpiringRenewalsMonthly.average(),
            totalValue: expiringCountsStat.reforecastExpiringRenewalRatiosMonthly.average() * 100,
            totalCount: expiringCountsStat.reforecastExpiringRenewalsMonthly.sum()
        },
        {
            year: property.reforecastYear,
            versionType: VersionType.Reforecast,
            name: `${property.reforecastYear % 100} REFORECAST - FORECAST`,
            color: COLORS.LAVENDER_200,
            type: "bar",
            values: new Array(property.reforecastStartMonthIndex).fill(null)
                .concat(expiringCountsStat.reforecastExpiringRenewalRatiosMonthly
                    .slice(property.reforecastStartMonthIndex, 12)
                    .map(v => v * 100)
                ),
            counts: expiringCountsStat.reforecastExpiringRenewalsMonthly,
            averageValue: expiringCountsStat.reforecastExpiringRenewalRatiosMonthly.average() * 100,
            averageCount: expiringCountsStat.reforecastExpiringRenewalsMonthly.average(),
            totalValue: expiringCountsStat.reforecastExpiringRenewalRatiosMonthly.average() * 100,
            totalCount: expiringCountsStat.reforecastExpiringRenewalsMonthly.sum()
        },
        {
            year: property.budgetYear,
            versionType: VersionType.Budget,
            name: `${property.budgetYear % 100} BUDGET`,
            color: COLORS.PRIMARY_400,
            type: "line",
            values: expiringCountsStat.budgetExpiringRenewalRatiosMonthly.map(v => v * 100),
            counts: expiringCountsStat.budgetExpiringRenewalsMonthly,
            averageValue: expiringCountsStat.budgetExpiringRenewalRatiosMonthly.average() * 100,
            averageCount: expiringCountsStat.budgetExpiringRenewalsMonthly.average(),
            totalValue: expiringCountsStat.budgetExpiringRenewalRatiosMonthly.average() * 100,
            totalCount: expiringCountsStat.budgetExpiringRenewalsMonthly.sum()
        },
    ];
    const summary = {
        comparisonItemOne: {
            label: `${property.reforecastYear % 100} RFCST`,
            value: expiringCountsStat.reforecastAvgExpiringRenewalRatio === null ? "-" : formatterPercentAutoSign.format(expiringCountsStat.reforecastAvgExpiringRenewalRatio)
        },
        comparisonItemTwo: {
            label: `${property.budgetYear % 100} BDGT`,
            value: expiringCountsStat.budgetAvgExpiringRenewalRatio === null ? "-" : formatterPercentAutoSign.format(expiringCountsStat.budgetAvgExpiringRenewalRatio)
        },
        variancePercent: {
            label: "% Change",
            value: expiringCountsStat.reforecastAvgExpiringRenewalRatio === null
                    && expiringCountsStat.budgetAvgExpiringRenewalRatio === null
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    ? "-" : formatterPercentAutoSign.format(expiringCountsStat.budgetAvgExpiringRenewalRatio! - expiringCountsStat.reforecastAvgExpiringRenewalRatio!)
        },
        varianceAmount: {
            label: "# Change",
            value: varianceCounts.average().toFixed(0)
        }
    };
    const tableData = getHotTableDataFromParsedSeriesData(
                        {varianceCounts, chartSeriesData},
                        summary,
                        "percent");
    return {
        dataType: "percent",
        varianceCounts: varianceCounts,
        chartSeriesData: chartSeriesData,
        summary: summary,
        tableData
    };
}

export function buildGraphSeriesDataFinancialMetric(financialMetricStat: TSummaryDataFinancialMetric, property: Property): ExecutiveSummaryGraphData {
    const chartSeriesData = [
        {
            year: property.reforecastYear,
            versionType: VersionType.Actuals,
            name: `${property.reforecastYear % 100} REFORECAST - ACTUAL`,
            color: COLORS.LAVENDER_400,
            type: "bar",
            values: financialMetricStat.reforecastMonthly
                .slice(0, property.reforecastStartMonthIndex)
                .concat(new Array(12 - property.reforecastStartMonthIndex).fill(null)),
            counts: [],
            averageValue: financialMetricStat.reforecastMonthly.average(),
            averageCount: financialMetricStat.reforecastMonthly.average(),
            totalValue: financialMetricStat.reforecastTotal,
            totalCount: financialMetricStat.reforecastTotal
        },
        {
            year: property.reforecastYear,
            versionType: VersionType.Reforecast,
            name: `${property.reforecastYear % 100} REFORECAST - FORECAST`,
            color: COLORS.LAVENDER_200,
            type: "bar",
            values: new Array(property.reforecastStartMonthIndex).fill(null)
                .concat(financialMetricStat.reforecastMonthly
                    .slice(property.reforecastStartMonthIndex, 12)
                ),
            counts: [],
            averageValue: financialMetricStat.reforecastMonthly.average(),
            averageCount: financialMetricStat.reforecastMonthly.average(),
            totalValue: financialMetricStat.reforecastTotal,
            totalCount: financialMetricStat.reforecastTotal
        },
        {
            year: property.budgetYear,
            versionType: VersionType.Budget,
            name: `${property.budgetYear % 100} BUDGET`,
            color: COLORS.PRIMARY_400,
            type: "line",
            values: financialMetricStat.budgetMonthly,
            counts: [],
            averageValue: financialMetricStat.budgetMonthly.average(),
            averageCount: financialMetricStat.budgetMonthly.average(),
            totalValue: financialMetricStat.budgetTotal,
            totalCount: financialMetricStat.budgetTotal
        },
    ];
    const summary = {
        comparisonItemOne: {
            label: `${property.reforecastYear % 100} RFCST`,
            value: formatterDollarUSNoDecimal.format(financialMetricStat.reforecastTotal)
        },
        comparisonItemTwo: {
            label: `${property.budgetYear % 100} BDGT`,
            value: formatterDollarUSNoDecimal.format(financialMetricStat.budgetTotal)
        },
        variancePercent: {
            label: "% Change",
            value: financialMetricStat.variancePercent ? formatterPercentAutoSign.format(financialMetricStat.variancePercent) : "-"
        },
        varianceAmount: {
            label: "$ Change",
            value: financialMetricStat.varianceAmount ? formatterDollarUSNoDecimal.format(financialMetricStat.varianceAmount) : "-"
        }
    };
    const tableData = getHotTableDataFromParsedSeriesData(
                        {varianceCounts: [], chartSeriesData},
                        summary,
                        "dollar");
    return {
        dataType: "dollar",
        varianceCounts: [],
        chartSeriesData: chartSeriesData,
        summary: summary,
        tableData
    };
}
