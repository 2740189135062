import { ReactElement, useEffect, useMemo, useState } from "react";
import { Dropdown, Field, Item, Menu, Multiselect } from "@zendeskgarden/react-dropdowns";
import { Tag } from "@zendeskgarden/react-tags";
import Downshift from "downshift";
import { FinancialEntity } from "../../../contexts/chartofaccounts/ChartOfAccountsContext";

export interface IAccountsSelectorProps {
    allAccounts: FinancialEntity[],
    selectedAccountIds: string[],
    onChangeCallback: (accountIds: string[]) => void,
}

function orderBy(unordered: string[], accounts: FinancialEntity[]): string[] {
    const pre = accounts.filter(acc => unordered.includes(acc.id)).sortBy("order");
    return pre.map(acc => acc.id);
}

export default function AccountsSelector(props: IAccountsSelectorProps): ReactElement {
    const [inputValue, setInputValue] = useState('');
    const [matchingOptions, setMatchingOptions] = useState<FinancialEntity[]>(props.allAccounts);
    const initialSelectedAccounts = useMemo(() => orderBy(props.selectedAccountIds, props.allAccounts), []);
    const [selectedAccounts, setSelectedAccounts] = useState<string[]>(initialSelectedAccounts);
    let requestDebounce: (ReturnType<typeof setTimeout> | null) = null;

    const stateReducer = (state: any, changes: any) => {
        switch (changes.type) {
            case Downshift.stateChangeTypes.keyDownEnter:
            case Downshift.stateChangeTypes.clickItem:
                return {
                    ...changes,
                    highlightedIndex: state.highlightedIndex,
                    isOpen: true,
                    inputValue: '',
                };
            default:
                return changes;
        }
    };

    useEffect(
        () => {
            const matchedOptions = props.allAccounts.filter(option => {
                return option.name.trim().toLowerCase().indexOf(inputValue.trim().toLowerCase()) !== -1;
            });

            setMatchingOptions(matchedOptions);
        }, [inputValue]
    );

    function handleSelect(selectedAccountIds: string[]) {
        setSelectedAccounts(orderBy(selectedAccountIds, props.allAccounts));
        if (requestDebounce) {
            clearTimeout(requestDebounce);
        }

        requestDebounce = setTimeout(() => {
            props.onChangeCallback(selectedAccountIds);
        }, 1000);
    }

    const renderOptions = () => {
        if (inputValue == null || inputValue == '') {
            return props.allAccounts.map(option => (
                <Item key={option.id} value={option.id}>
                    <span>{option.name}</span>
                </Item>
            ));
        }

        if (matchingOptions.length === 0) {
            return <Item disabled>No account found</Item>;
        }

        return matchingOptions.map(option => (
            <Item key={option.id} value={option.id}>
                <span>{option.name}</span>
            </Item>
        ));
    };

    return (
        <Dropdown
            inputValue={inputValue}
            selectedItems={selectedAccounts}
            onSelect={items => {
                handleSelect(items);
            }}
            downshiftProps={{ defaultHighlightedIndex: 0, stateReducer }}
            onInputValueChange={value => setInputValue(value)}
        >
            <Field>
                <Multiselect
                    isCompact
                    placeholder={"Select GL Accounts"}
                    renderItem={({ value, removeValue }) => (
                        <Tag>
                            <span>{props.allAccounts.find(x => x.id == value)?.name}</span>
                            <Tag.Close onClick={removeValue}
                            />
                        </Tag>
                    )}
                />
            </Field>
            <Menu>
                {renderOptions()}
            </Menu>
        </Dropdown>
    );
}