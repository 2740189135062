import { ReactElement } from "react";
import { ReportTableData } from "../logic/reportTablesLogic";
import { Body, Cell, Head, HeaderCell, HeaderRow, Row, SortableCell, Table } from "@zendeskgarden/react-tables";
import { formatterDollarUSNoDecimal, formatterPercentWithSign } from "../../../utils/formatters";

export function FinancialReportTable(props: {reforecastYear: number, budgetYear: number, data: ReportTableData}): ReactElement {
    const {reforecastYear, budgetYear, data} = props;
    return (
        <div>
            <span>{data.name}</span>
            <Table style={{ minWidth: 500 }}>
                <Head>
                    <HeaderRow>
                        <HeaderCell>Line</HeaderCell>
                        <SortableCell
                            onClick={() => {
                                console.log("sort requested");
                                // if (requesterSort === 'asc') {
                                // setRequesterSort('desc');
                                // } else if (requesterSort === 'desc') {
                                // setRequesterSort(undefined);
                                // } else {
                                // setRequesterSort('asc');
                                // }
                                // setTypeSort(undefined);
                                // setData(data);
                            }}
                            sort={undefined}
                        >
                            {reforecastYear} Total
                        </SortableCell>
                        <SortableCell
                            onClick={() => {
                                // if (typeSort === 'asc') {
                                // setTypeSort('desc');
                                // } else if (typeSort === 'desc') {
                                // setTypeSort(undefined);
                                // } else {
                                // setTypeSort('asc');
                                // }
                                // setRequesterSort(undefined);
                                // setData(data);
                            }}
                            sort={undefined}
                        >
                            {budgetYear} Total
                        </SortableCell>
                        <SortableCell
                            onClick={() => {
                                // if (typeSort === 'asc') {
                                // setTypeSort('desc');
                                // } else if (typeSort === 'desc') {
                                // setTypeSort(undefined);
                                // } else {
                                // setTypeSort('asc');
                                // }
                                // setRequesterSort(undefined);
                                // setData(data);
                            }}
                            sort={undefined}
                        >
                            Growth $
                        </SortableCell>
                        <SortableCell
                            onClick={() => {
                                // if (typeSort === 'asc') {
                                // setTypeSort('desc');
                                // } else if (typeSort === 'desc') {
                                // setTypeSort(undefined);
                                // } else {
                                // setTypeSort('asc');
                                // }
                                // setRequesterSort(undefined);
                                // setData(data);
                            }}
                            sort={undefined}
                        >
                            Growth %
                        </SortableCell>
                        <SortableCell
                            onClick={() => {
                                // if (typeSort === 'asc') {
                                // setTypeSort('desc');
                                // } else if (typeSort === 'desc') {
                                // setTypeSort(undefined);
                                // } else {
                                // setTypeSort('asc');
                                // }
                                // setRequesterSort(undefined);
                                // setData(data);
                            }}
                            sort={undefined}
                        >
                            {budgetYear} Per Unit
                        </SortableCell>
                    </HeaderRow>
                </Head>
                <Body>
                    {data.rows.sortBy("order").map(row => (
                        <Row key={row.accountId}>
                            <Cell>{row.name}</Cell>
                            <Cell>{row.reforecastTotal === null ? "-" : formatterDollarUSNoDecimal.format(row.reforecastTotal)}</Cell>
                            <Cell>{row.budgetTotal === null ? "-" : formatterDollarUSNoDecimal.format(row.budgetTotal)}</Cell>
                            <Cell>{row.varianceAmount === null ? "-" : formatterDollarUSNoDecimal.format(row.varianceAmount)}</Cell>
                            <Cell>{row.variancePercent === null ? "-" : formatterPercentWithSign.format(row.variancePercent)}</Cell>
                            <Cell>{row.budgetPerUnit === null ? "-" : formatterDollarUSNoDecimal.format(row.budgetPerUnit)}</Cell>
                        </Row>
                    ))}
                </Body>
            </Table>
        </div>
    );
}